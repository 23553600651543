.feedback:after {
  display:block;
  content: '';
  border-bottom: 10px solid #FED072;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  transform-origin:  0% 50%; 
  justify-content: center;
  margin:0 auto;
}

.feedback-notes:after {
  display:block;
  content: '';
  border-bottom: 10px solid #FED072;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  transform-origin:  0% 50%; 
  justify-content: center;
  width: 185px;
  margin:0 auto;
}

.feedback:hover:after { transform: scaleX(1); }
.feedback-notes:hover:after { transform: scaleX(1); }