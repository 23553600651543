.content-data > p > a {
  color: #33ACE3;
}

.content-data > p > a:hover {
  text-decoration: underline;
}

.content-data > a {
  color: #33ACE3;
}

.content-data > a:hover {
  text-decoration: underline;
}