input[type='checkbox']:checked {
  background-image: none;
  border-radius: 20px !important;
}

input[type='checkbox'] {
  border: none;
  border-radius: 20px !important;
}

input[type='radio']:checked {
  background-image: none;
  border-radius: 20px !important;
}

input[type='radio'] {
  border: none;
  border-radius: 20px !important;
}