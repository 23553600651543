.feedback:after {
    display:block;
    content: '';
    border-bottom: 10px solid #FED072;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
    transform-origin:  0% 50%; 
    justify-content: center;
    margin:0 auto;
}

.feedback:hover:after { transform: scaleX(1); }

p.comment:after {
    display:block;
    content: '';
    border-bottom: 6px solid #FED072;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
    transform-origin:  0% 50%; 
    justify-content: center;
}

p:hover:after { transform: scaleX(1); }
