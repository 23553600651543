@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Brandon-Bold";
  src: url("../src/Assets/Fonts/Brandon_bold.otf");
}

@font-face {
  font-family: "Brandon-Medium";
  src: url("../src/Assets/Fonts/Brandon_medium.otf");
}

@font-face {
  font-family: "Brandon-Regular";
  src: url("../src/Assets/Fonts/Brandon_regular.otf");
}

* {
  @apply font-brandon;
}

.input-primary {
  @apply rounded-input border-2 border-ileads-lightblue h-10;
}

.input-error {
  @apply rounded-input border-2 border-ileads-red h-10;
}

.textarea-primary {
  @apply rounded-input border-2 border-ileads-lightblue h-24;
}

.textarea-error {
  @apply rounded-input border-2 border-ileads-red h-24;
}

.react-datepicker {
  font-family: "Brandon-Regular";
  color: #204b9a;
}

.react-datepicker__day--selected {
  background-color: #fed072;
  border-radius: 50px;
  color: #204b9a;
  font-family: "Brandon-Bold";
}

.react-datepicker__current-month {
  font-family: "Brandon-Medium";
  color: #204b9a;
}

.react-datepicker__day {
  color: #204b9a;
}

.react-datepicker__day-name {
  font-family: "Brandon-Medium";
  color: #204b9a;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fed072;
  border-radius: 50px;
  color: #204b9a;
  font-family: "Brandon-Bold";
}

.input-password-custom {
  border: none !important;
  // border-bottom: 0.8px solid #c4c4c4 !important;
  background-color: transparent;
  &:focus {
    outline: none !important;
    outline-color: transparent !important;
    box-shadow: none !important;
    // border-bottom: 0.8px solid #c4c4c4 !important;
  }
}

.h-card-content {
  height: 523px;
}
